import api from '../library/axios';

export default {
  getList(workspace_id, company_id, page, search) {
    return api({
      method: 'get',
      url: `/api/channels/${workspace_id}`,
      params: {
        company_id,
        page,
        search,
      },
    });
  },
  getAll(page, search) {
    return api({
      method: 'get',
      url: '/api/channels',
      params: {
        page,
        search,
      },
    });
  },
  createChannel(workspace_id, payload) {
    return api({
      method: 'post',
      url: `/api/channels/${workspace_id}`,
      data: payload,
    });
  },
  delete(workspace_id, channel_id) {
    return api({
      method: 'delete',
      url: `/api/channels/${workspace_id}/${channel_id}`,
    });
  },
  templateList({
    workspace_id,
    channel_id,
    company_id,
  }) {
    return api({
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}/${company_id}`,
    });
  },
  checkTelegramProfile({
    type,
    token,
    workspace_id,
  }) {
    return api({
      method: 'post',
      url: `/api/channels/${workspace_id}/inquiry`,
      data: {
        type,
        token,
      },
    });
  },
  initLinkAge({ token }) {
    return api({
      method: 'post',
      url: '/api/wa-embed-signup/init',
      data: {
        token,
      },
    });
  },
  getEmbedPhoneNumber() {
    return api({
      url: '/api/wa-embed-signup/phone-numbers',
    });
  },
  getChannelProfile({
    channel_id,
    workspace_id,
  }) {
    return api({
      url: `/api/channels/${workspace_id}/${channel_id}/profile`,
    });
  },
  updateChannelProfile({
    channel_id,
    workspace_id,
    data,
  }) {
    return api({
      method: 'patch',
      url: `/api/channels/${workspace_id}/${channel_id}/profile`,
      data,
    });
  },
  getWorkspaces() {
    return api({
      method: 'get',
      url: '/api/backoffice/channels/workspaces',
    });
  },
  getDetail(key) {
    return api({
      method: 'get',
      url: `/api/backoffice/channels/waba/detail/${key}`,
    });
  },
  checkExist(form) {
    return api({
      method: 'post',
      url: '/api/backoffice/channels/waba/check',
      data: form,
    });
  },
  getOTP(form) {
    return api({
      method: 'post',
      url: '/api/backoffice/channels/waba/code',
      data: form,
    });
  },
  submitOTP(form) {
    return api({
      method: 'post',
      url: '/api/backoffice/channels/waba/verify',
      data: form,
    });
  },
  saveWorkspace(form) {
    return api({
      method: 'post',
      url: '/api/backoffice/channels/save/workspace',
      data: form,
    });
  },
};
