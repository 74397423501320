import api from '../library/axios';
import ls from '../library/localStorage';

export default {
  getList(page, search, company_id) {
    return api({
      method: 'get',
      url: `/api/backoffice/company/documents/${company_id}`,
      params: {
        page,
        search,
        company_id,
      },
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  createDocument({
    category,
    file_name,
    file,
    company_id,
    workspace_ids,
  }) {
    return api({
      method: 'post',
      url: `/api/backoffice/company/documents/${company_id}`,
      data: {
        category,
        file_name,
        file_path: file,
        workspace_ids,
      },
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  updateDocument({
    category,
    file_name,
    file,
    company_id,
    id,
    workspace_ids,
  }) {
    return api({
      method: 'patch',
      url: `/api/backoffice/company/documents/${company_id}/${id}`,
      data: {
        category,
        file_name,
        company_id,
        file_path: file,
        workspace_ids,
      },
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  deleteDocument(company_id, document_id) {
    return api({
      method: 'delete',
      url: `/api/backoffice/company/documents/${company_id}/${document_id}`,
      headers: {
        Authorization: `Bearer ${ls.get('token')}`,
      },
    });
  },
  getCompanies(page, search_by, search_value) {
    return api({
      method: 'get',
      url: '/api/backoffice/company',
      params: {
        page,
        search_by,
        search_value,
      },
    });
  },
};
