<template>
  <div>
    <b-modal v-model="showModalAdd" title="Add Channel" hide-footer @hide="onHide">
      <!-- <b-form ref="formAddChannel" @submit.prevent="submitOTP" @reset="closeModal"> -->
      <div v-if="step === 1" v-loading="loading.next">
        <b-card-title class="text-capitalize">WhatsApp Bussiness Channel</b-card-title>
        <b-row class="mb-4">
          <b-col sm="6" md="6" class="mb-2">
            <div class="list-group">
              <a @click="setCategory('new')" :class="{ active: 'new' == form.category }" class="list-group-item list-group-item-action">
                NEW CHANNEL
              </a>
            </div>
          </b-col>
          <b-col sm="6" md="6" class="mb-2">
            <div class="list-group">
              <a @click="setCategory('exist')" :class="{ active: 'exist' == form.category }" class="list-group-item list-group-item-action">
                EXISTING
              </a>
            </div>
          </b-col>
        </b-row>
        <b-card-sub-title class="mb-2">
          Input WhatsApp Bussiness ID to get phone number list.
        </b-card-sub-title>
        <b-row class="mb-4">
          <b-col>
            <el-input
              @change="handlerGetDetail"
              type="text"
              placeholder="Input WhatsApp Bussiness ID"
              v-model="form.waba_id"
              maxlength="512"
              show-word-limit
            />
          </b-col>
        </b-row>
        <div v-loading="loading.detail">
          <b-card-sub-title class="mb-2">
            Name : {{ this.show.waba_name }}
          </b-card-sub-title>
          <b-row class="mb-2">
            <b-col>
              <el-select filterable :filter-method="handlerFilterPhoneNumber" class="w-100" placeholder="Select Phone Number" v-model="form.phone_number_id">
                <el-option v-for="(item) in phone_number_list_filter" :disabled="isDisabledPhoneNumber(item)" :value="item.id" :label="`${item.verified_name} (${item.display_phone_number})`" :key="item.id">{{ item.verified_name }} ({{ item.display_phone_number }})</el-option>
              </el-select> &nbsp;
            </b-col>
          </b-row>
        </div>
        <div v-if="isChannelExist">
          <b-card-sub-title class="mb-2"> WhatsApp Bussiness ID exist in workspace : </b-card-sub-title>
          <b-card-title class="text-capitalize">{{ this.show.workspace_name }}</b-card-title>
        </div>
        <hr/>
        <div class="d-flex float-right">
          <el-button @click="onHide" v-if="isChannelExist" size="small" type="default">Done</el-button>
          <el-button @click="nextStep(step)" :disabled="!isValidStep1" size="small" type="primary">Continue</el-button>
        </div>
      </div>
      <div v-if="step === 2 && this.form.category === 'new'" v-loading="loading.next">
        <b-card-title class="text-capitalize">Input OPT Code</b-card-title>
        <b-card-sub-title class="mb-4">
          Give otp code to verified.
        </b-card-sub-title>
        <b-row>
          <b-col>
            <el-input
              type="text"
              placeholder="Input OTP"
              v-model="form.otp_code"
              maxlength="512"
              show-word-limit
            />
          </b-col>
        </b-row>
        <b-row v-if="!request_otp_at || this.timerCount < 1">
          <b-col>
            <div class="d-flex float-right mt-1 mb-2">
              <b-link @click="nextStep(step)" v-if="isValidStep1">Request OTP Code</b-link>
            </div>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col>
            <div class="d-flex float-right mt-2 mb-2">
              <b-card-sub-title>00 : {{ this.timerCount }}</b-card-sub-title>
            </div>
          </b-col>
        </b-row>
        <hr/>
        <div class="d-flex float-right">
          <el-button @click="nextStep(step)" :disabled="!isValidStep2" size="small" type="primary">Continue</el-button>
          <el-button @click="backStep(step)" type="default" size="small">Back</el-button>
        </div>
      </div>
      <div v-if="step === 3">
        <b-card-title class="text-capitalize">Choose Workspace</b-card-title>
        <b-card-sub-title class="mb-4">
          Add channel to workspace (optional).
        </b-card-sub-title>
        <b-row>
          <b-col>
            <el-select filterable :filter-method="handlerFilterWorkspace" class="w-100" placeholder="Select Workspace" v-model="form.workspace_id">
              <el-option v-for="(item) in workspace_list_filter" :value="item.id" :label="item.label" :key="item.id">{{ item.label }}</el-option>
            </el-select> &nbsp;
          </b-col>
        </b-row>
        <hr/>
        <div class="d-flex float-right">
          <!-- <el-button @click="onHide" type="default" size="small">Done</el-button> -->
          <el-button @click="backStep(step)" type="default" size="small">Back</el-button>
          <el-button @click="saveWorkspace" size="small" type="primary">Submit</el-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Moment from 'moment';
import countdown from 'countdown';
import _ from 'lodash';

import popupErrorMessages from '../../../library/popup-error-messages';
import channelAPI from '../../../api/channels';

export default {
  name: 'addChannel',
  metaInfo: {
    title: 'Add Channel',
  },
  props: {
    show_form: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    show_form() {
      this.showModalAdd = this.show_form;
    },
  },
  beforeCreate() {
    this.isEmpty = _.isEmpty;
  },
  data() {
    return {
      workspace_list_filter: [],
      workspace_list: [],
      phone_number_list_filter: [],
      phone_number_list: [],
      loading: {
        next: false,
        submit: false,
        channel: false,
        detail: false,
      },
      pin_enable: true,
      form: {
        category: '',
        waba_id: '',
        phone_number_id: '',
        otp_code: '',
        workspace_id: '',
      },
      show: {
        workspace_name: '-',
        waba_name: '-',
      },
      showModalAdd: false,
      step: 1,
      loaderStack: 0,
      loader: null,
      last_index: null,
      channel: 'whatsapp',
      request_otp_at: '',
      timerCount: 0,
    };
  },
  computed: {
    checkUser() {
      return this.$store.state.backOffice.userProfile;
    },
    checkPermission() {
      const users = this.$store.state.backOffice.userProfile;
      const path = this.$router.currentRoute.fullPath;
      const permission = users.permissions.find((v) => path.includes(v.path));
      return permission.configuration;
    },
    isValidStep1() {
      return this.show.waba_name !== '-' && this.form.category !== '' && this.form.waba_id !== '' && this.form.phone_number_id !== '';
    },
    isValidStep2() {
      return this.show.waba_name !== '-' && this.form.category !== '' && this.form.waba_id !== '' && this.form.phone_number_id !== '' && this.form.otp_code !== '';
    },
    isValidSubmit() {
      return this.form.workspace_id !== '' && this.form.waba_id !== '' && this.form.phone_number_id !== '';
    },
    isChannelExist() {
      if (this.show.workspace_name !== '-' && this.form.category === 'exist') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.getWorkspaces();
  },
  methods: {
    countDownTimer() {
      if (this.timerCount > 0) {
        setTimeout(() => {
          const getTime = countdown(Moment(this.request_otp_at).add(1, 'minutes'), Moment(), countdown.ALL);
          this.timerCount = getTime.seconds;
          this.countDownTimer();
        }, 1000);
      } else {
        this.request_otp_at = '';
      }
    },
    onHide() {
      this.$emit('onHide');
      this.form = {
        category: '',
        waba_id: '',
        phone_number_id: '',
        otp_code: '',
        workspace_id: '',
      };
      this.show = {
        workspace_name: '-',
        waba_name: '-',
      };
      this.step = 1;
    },
    setCategory(key) {
      this.form.category = key;
      this.form.phone_number_id = '';
      this.show.workspace_name = '-';
    },
    isDisabledPhoneNumber(item) {
      if (this.form.category === 'new') {
        return item.platform_type !== 'NOT_APPLICABLE';
      }
      if (this.form.category === 'exist') {
        return item.platform_type !== 'CLOUD_API';
      }
      return true;
    },
    async getWorkspaces() {
      await channelAPI.getWorkspaces()
        .then(async (res) => {
          this.workspace_list = res.data;
          this.workspace_list_filter = res.data;
        }).catch((err) => {
          console.log(err);
        });
    },
    handlerFilterWorkspace(keyword) {
      if (keyword) {
        this.workspace_list_filter = this.workspace_list.filter((v) => v.label.toLowerCase().includes(keyword.toLowerCase()));
      } else {
        this.workspace_list_filter = this.workspace_list.map((v) => v);
      }
    },
    async handlerGetDetail(key) {
      this.loading.detail = true;
      this.show.waba_name = '-';
      this.show.workspace_name = '-';
      this.form.phone_number_id = '';
      this.phone_number_list = [];
      this.phone_number_list_filter = [];
      await channelAPI.getDetail(key)
        .then(async (res) => {
          await popupErrorMessages(res);
          if (res.data) {
            this.show.waba_name = res.data.detail.name;
            this.phone_number_list = res.data.list.data;
            this.phone_number_list_filter = res.data.list.data;
          }
        }).catch((err) => {
          console.log(err);
        });
      this.loading.detail = false;
    },
    handlerFilterPhoneNumber(keyword) {
      if (keyword) {
        this.phone_number_list_filter = this.phone_number_list.filter((v) => v.verified_name.toLowerCase().includes(keyword.toLowerCase()) || v.display_phone_number.toLowerCase().includes(keyword.toLowerCase()));
      } else {
        this.phone_number_list_filter = this.phone_number_list.map((v) => v);
      }
    },
    async backStep(fromStep) {
      if (fromStep === 3 && this.form.category === 'exist') {
        this.step = 1;
      } else {
        this.step = fromStep - 1;
      }
    },
    async nextStep(fromStep) {
      this.loading.next = true;
      if (fromStep === 1 && this.form.category === 'new') {
        await channelAPI.getOTP(this.form)
          .then(async (response) => {
            await popupErrorMessages(response);
            if (!_.isEmpty(response.data)) {
              this.$message({
                title: this.$t('general.success'),
                type: 'success',
                message: this.$t('general.waiting_download'),
                duration: 3 * 1000,
                showClose: true,
              });
              this.request_otp_at = response.data.request_otp_at;
              const getTime = countdown(Moment(this.request_otp_at).add(1, 'minutes'), Moment(), countdown.ALL);
              this.timerCount = getTime?.seconds;
              this.step = 2;
              this.loading.next = false;
              this.countDownTimer();
            } else {
              this.$message({
                title: this.$t('failed'),
                type: 'error',
                message: this.$t('general.error.not_found'),
                duration: 30 * 1000,
                showClose: true,
              });
              this.request_otp_at = '';
              this.timerCount = 0;
              this.loading.next = false;
            }
          }).catch(() => {
            this.loading.next = false;
            this.step = fromStep;
          });
      } else if (fromStep === 1 && this.form.category === 'exist') {
        this.show.workspace_name = '-';
        const response = await channelAPI.checkExist(this.form)
          .catch((err) => {
            this.loading.next = false;
            console.log(err);
          });
        await popupErrorMessages(response);
        if (_.isEmpty(response.data)) {
          this.$message({
            title: this.$t('general.success'),
            type: 'success',
            message: this.$t('channels.success.add'),
            duration: 3 * 1000,
            showClose: true,
          });
          this.step = 3;
        } else if (response.data?.name) {
          this.show.workspace_name = response.data.name;
          this.step = 1;
        } else {
          this.$message({
            title: this.$t('general.success'),
            type: 'success',
            message: this.$t('channels.exist'),
            duration: 3 * 1000,
            showClose: true,
          });
          this.step = 3;
        }
      } else if (fromStep === 2 && this.form.category === 'new') {
        const response = await channelAPI.submitOTP(this.form)
          .catch((err) => {
            this.loading.next = false;
            console.log(err);
          });
        await popupErrorMessages(response);
        if (_.isEmpty(response.data)) {
          this.$message({
            title: this.$t('general.success'),
            type: 'success',
            message: this.$t('channels.success.add'),
            duration: 3 * 1000,
            showClose: true,
          });
          this.step = 3;
        }
      }
      this.loading.next = false;
    },
    async saveWorkspace() {
      if (this.form.workspace_id === '') {
        this.onHide();
      } else {
        this.showLoader();
        this.loading.submit = true;
        const response = await channelAPI.saveWorkspace(this.form)
          .catch((err) => {
            this.loading.submit = false;
            console.log(err);
          });
        await popupErrorMessages(response);
        this.onHide();
      }
      this.$message({
        title: this.$t('general.success'),
        type: 'success',
        message: this.$t('channels.success.edit'),
        duration: 3 * 1000,
        showClose: true,
      });
      this.form = {
        category: '',
        waba_id: '',
        phone_number_id: '',
        otp_code: '',
        workspace_id: '',
      };
      this.show = {
        workspace_name: '-',
        waba_name: '-',
      };
      this.step = 1;
      this.loading.submit = false;
      this.hideLoader();
    },
    moreText(string) {
      return string && string.length > 50 ? `${string.substr(0, 40)}...` : string;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
  },
};
</script>
